<template>
  <section class="py-5 store-page">
      <div class="container pb-lg-4 bg-dark" style="padding-top: 30px;">
        <div dir="rtl" class="d-flex align-item-center title mb-3">
          <h5 style="color: #000;font-weight: 600 !important;text-align: center;width: 100%;" class="m-0 font-weight-normal">كل العاب الاستراتيجية<span class="text-muted"></span>
          </h5>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="gradient">
              <div class="row" dir="rtl">
                <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="game in strategy" :key="game.id">
                  <div class="osahan-card pointer">
                    <a @click="getFeed(game)">
                    <img class="img-fluid" v-lazy="game.thumbnailUrl" alt="">
                      <div class="osahan-card-body mt-3">
                        <h6 class="text-dark mb-1">{{ game.title_ar }}</h6>
                        <p class="mb-0 text-muted">إستراتيجية</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { ref } from '@vue/reactivity';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'AppStrategy',
   setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const strategy = ref([]);
      try {
         HTTP.get(`CatogeryById.php?Cat_id=Strategy&LIMIT=50`).then((res) => {
          strategy.value = res.data.getCatogery; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (game) => {
        router.push({ name: "GamesDetails", params: { gid: game.gid } });
      };

      return {
        getFeed,
        strategy
      };
   }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

</style>